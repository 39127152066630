@mixin select-arrow() {
  &:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    right: 20px;
    top: calc(50% - 10px);
    background-image: url('~src/assets/images/arrow.png');
    background-size: cover;
    pointer-events: none;
  }
}

@mixin select-indent($px) {
  text-indent: $px;
}
