@-webkit-keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
