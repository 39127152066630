// these are defined in the style guide!
// don't add new colors unless robert clears them !!

$white: #fff;
$product-bg-gray: #f5f5f5;
$faint-gray: #f5f5f2;
$light-gray: #e2e2df;
$mid-gray: #b2b2af;
$dark-gray: #5e5e5e;
$darkest-gray: #242424;
$black: #000;
$error-invalid-red: #e44f5c;
$success-valid-green: #83c469;
$dark-navy: #343f5d;

$primary-color-new: #e85c40; //orange-red
$primary-color-faded: #e18370; //orange-red faded
$primary-color-new-hover: #b73b23; //darker orange-red

$secondary-color-new: #f2be7d; //gold
$secondary-color-faded: #bd8846; //gold faded
$tertiary-color: #2d3f70; //navy

$tote-swap-yellow: $secondary-color-new;
$tote-swap-red: $primary-color-new;
$tote-swap-beige: #f5f5f2;
$tote-tracker-bar-orange: #f3be7d;

// this is not in the style guide,
// but robert used it so I am going to roll with it
$fainter-gray: #eeeeee;
$footer-blue: #32435c;
$footer-heading-color: #242424;

// these are colors from the old site
// dont use me
$primary-color: #fb6d58;
$primary-color-hover: rgba(251, 109, 88, 0.67);
$orange: #fd6c53;
$info-blue: #4a90e2;
$steel-gray: #707070;
$powder-pink: #fdf2f0;
$medium-gray: #bababa;
$muted-coral: #ff9988;
$lt-gray: #4d4d4f;
$lightest-gray: #ececec;
$lighter-gray: #ebebeb;
$coral: #fd6c53;
