.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

a.no-style {
  color: inherit !important;
  text-decoration: inherit !important;
}

.left {
  text-align: left;
  justify-content: flex-start;
}

.center {
  text-align: center;
  justify-content: center;
}

.right {
  text-align: right;
  justify-content: flex-end;
}

.flex {
  display: flex;
}
