/* // override bootstrap variables */

$enable-flex: true;
/*
// override bootstrap grid, for now, to work with old navbar
// this is going to get removed later
//$grid-breakpoints: (sm: 992px);

// this is for the header but it is in here so that
// it does not get picked up by the old site
*/

body {
  // padding-top: 81px;
  min-width: 960px;
  font-variant-numeric: lining-nums;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif !important;
}

body.mobile {
  min-width: 100%;
  padding-top: 0px;
}

input,
select {
  outline: none !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html.modal-open {
  body {
    overflow: hidden;
  }
}

.hide {
  display: none !important;
}

.btn {
  outline: none !important;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
  user-select: none !important;
}

.mid {
  margin: 0 auto;
}

.btn-primary {
  border-color: #fff !important;
  background-color: #e85c40 !important;
}

@import 'animations';
// @import 'fonts';
@import 'colors';
@import 'utilities';
@import 'forms';
@import 'bootstrap/bootstrap';
@import 'mixins/buttons';
@import 'mixins/select';
@import 'mixins/dropdown';
@import 'mixins/inputs';
@import 'desktop/buttons';